<template>
  <div class="banner-manage" v-if="show">
    <div class="banner-manage-box">
      <i class="close el-icon-close" @click="close"></i>
      <h3 class="title">编辑金刚位</h3>
      <p class="word">
        <span class="word-title">上传金刚位图片</span
        ><span class="word-text">推荐上传尺寸为：400X400 px</span>
      </p>
      <upload :info="imgInfo" :size="imageSize" class="upload-img" @getimage="getChange" />
      <p class="word">
        <span class="word-title">名称</span>
      </p>
      <el-input v-model="name" placeholder="请输入名称"></el-input>
      <el-select v-model="params.redirect_type" style="margin-top:15px;" placeholder="请选择跳转类型">
        <el-option :value="1" label="APP内跳转"></el-option>
        <el-option :value="2" label="搜索页"></el-option>
        <el-option :value="3" label="帮助中心"></el-option>
        <el-option :value="4" label="发现页-商品分类页"></el-option>
        <el-option :value="5" label="随心购列表页"></el-option>
      </el-select>
      <el-input v-if="params.redirect_type == 1" v-model="params.redirect_url_inner_app"  style="margin-top:15px;" placeholder="请填入链接，仅支持rennigou.jp域名"></el-input>
      <div>
        <el-select v-if="params.redirect_type == 2" v-model="params.redirect_site" placeholder="请选择站点" multiple style="margin-top:15px;" @change="getCategory(0,1)">
          <el-option v-for="item in siteList" :key="item.site_id" :value="item.site_id" :label="item.proxy_name"></el-option>
        </el-select>
      </div>
      <div v-if="params.redirect_type == 2" class="item">
        <el-select v-model="category.first" @change="getCategory(category.first,2)">
          <el-option v-for="item in firstList" :key="item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
        </el-select>
        <el-select v-if="category.first" v-model="category.second" @change="getCategory(category.second,3)">
          <el-option v-for="item in secondList" :key="item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
        </el-select>
        <el-select v-if="category.second" v-model="category.third">
          <el-option v-for="item in thirdList" :key="item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
        </el-select>
      </div>
      <el-input v-if="params.redirect_type == 2" v-model="params.search_text" style="margin-top:15px;" placeholder="请输入搜索文本"></el-input>
      <footer class="banner-manage-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
      </footer>
    </div>
  </div>
</template>
<script>
import Upload from "@/components/common/Upload";
import { defineComponent, ref, watch, reactive } from "@vue/composition-api";

export default defineComponent({
  components: { Upload },
  model: {
    prop: 'show',
    event: 'change'
  },
  props: {
    show: { type: Boolean, default: false },
    info: { type: Object, default: ()=>{} },
  },
  setup(props, { emit, root }) {
    const imgInfo = reactive({url: '', width: 400, height: 400});
    const name = ref('')
    const imageSize = {};
    const params = reactive({
      id: '',
      img: '',
      name: '',
      redirect_type: 1,
      redirect_url_inner_app: '',
      redirect_site: '',
      search_text: '',
      redirect_platform_category: '',
    })
    const siteList = ref([])
    const firstList = ref([])
    const secondList = ref([])
    const thirdList = ref([])
    watch(props.info,val=>{
      if(!name.value) {
        name.value = props.info.data.name
      }
      const info = Object.assign({},val.data)
      imgInfo.url = info.url
      Object.assign(params,val.data)
      if(params.redirect_type == 2) {
        getCategory(0,1)
      }
    })
    const category = reactive({
      first: '',
      second: '',
      third: '',
    })
    props.info.width = 400
    props.info.height = 400
    watch(()=>category.first,()=>{
      secondList.value = []
      thirdList.value = []
      category.second = ''
      category.third = ''
      params.redirect_platform_category = ''
    })
    watch(()=>category.second,()=>{
      thirdList.value = []
      category.third = ''
      params.redirect_platform_category = ''
    })
    const close = ()=>{
      name.value = ''
      imgInfo.url = ''
      for(let key in category) {
        category[key] = ''
      }
      for(let key in params) {
        params[key] = ''
      }
      emit('change', false)
    }
    const getSite = () => {
      root.$axios.get(`/common/business/site/getSimplifyList`)
      .then((res) => {
        siteList.value = res.data;
      })
      
    }
    getSite()
    const getCategory = (id,type) => {
      root.$axios.get(`/common/business/PlatformCategory/getList`,{
        params: {
          p_id: id
        }
      })
      .then(res=>{
        params.children_category_id = ''
        if(type == 1) {
          category.first = params.categoryInfo.firstLevelId
          firstList.value = res.data
          if(params.categoryInfo.firstLevelId) {
            getCategory(params.categoryInfo.firstLevelId,2)
          }
        } else if(type == 2) {
          category.second = params.categoryInfo.secondLevelId
          secondList.value = res.data
          if(params.categoryInfo.secondLevelId) {
            getCategory(params.categoryInfo.secondLevelId,3)
          }
        } else if(type == 3) {
          category.third = params.categoryInfo.thirdLevelId
          thirdList.value = res.data
        }
      })
      
    }
    const confirm = async() => {
      try {
        if(!props.info.data.url) {
          root.$message.warning('请选择图片')
          return
        }
        if(!name.value || !name.value.trim()) {
          root.$message.warning('请输入名称')
          return
        }
        if(!params.redirect_type) {
          root.$message.warning('请选择跳转类型')
          return
        }
        if(params.redirect_type == 1 && !params.redirect_url_inner_app) {
          root.$message.warning('请输入跳转链接')
          return
        }
        if(params.redirect_type == 2 && params.redirect_site && params.redirect_site.length == 0) {
          root.$message.warning('请选择站点')
          return
        }
        if(params.redirect_type == 2 && !category.third && !params.search_text) {
          root.$message.warning('三级分类和搜索文本最少选择一个')
          return
        }
        params.redirect_platform_category = category.third
        const res = await root.$axios.post('/cms/jingangwei/save',{
          id: props.info.data.id,
          img: props.info.data.url,
          name: name.value,
          redirect_type: params.redirect_type,
          redirect_url_inner_app: params.redirect_type == 1 ? params.redirect_url_inner_app : '',
          redirect_site: params.redirect_site,
          search_text: params.search_text,
          redirect_platform_category: params.redirect_platform_category
        })
        if(res.code === 10000) {
          root.$message.success('修改成功')
          close()
        }
      } catch (error) {
        root.$message.error(error)
      }
    }
    const getChange = (data) => {
      props.info.data.url = data
      props.info.url = data
    }
    return {
      imgInfo,
      name,
      params,
      siteList,
      firstList,
      secondList,
      thirdList,
      category,
      close,
      confirm,
      imageSize,
      getChange,
      getCategory
    };
  },
});
</script>
<style lang="scss" scoped>
.item{
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.banner-manage {
  @include go-wrap();
  .banner-manage-box {
    text-align: left;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 680px;
    height: 544px;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 26px;
    overflow-y: auto;
    .close {
      cursor: pointer;
      font-size: 24px;
      position: absolute;
      right: 25px;
      top: 25px;
      color: $lightColor;
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      color: $titleColor;
      line-height: 28px;
      margin-bottom: 26px;
    }
    .word {
      margin-bottom: 14px;
      .word-title {
        font-size: 16px;
        font-weight: 400;
        color: $titleColor;
        line-height: 22px;
      }
      .word-text {
        margin-left: 10px;
        color: $dangerColor;
      }
    }
    .upload-img {
      width: 238px;
      height: 238px;
      padding: 14px;
      background-color: #fafafa;
      margin-bottom: 36px;
      ::v-deep p{
        font-size: 12px !important;
      }
    }
    .banner-manage-footer{
      margin-top: 36px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .el-button{
        width: 120px;
        height: 36px;
        margin-left: 24px;
      }
    }
  }
}
</style>