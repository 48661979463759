<template>
  <div class="table">
    <header class="table-header">
      <el-button type="primary" @click="getShowNum">更改显示个数</el-button>
    </header>
    <DragTable :listQuery="list" :header="header" :sort="true" @change="handle">
      <template #image="{row}">
        <img class="table-img" :src="`https://${row.img}`" />
      </template>
      <template #jump="{row}">{{redirect_type[row.redirect_type]}}</template>
      <template #control="{row}">
        <div class="control">
          <span @click="bannerEdit(row)">编辑</span>
        </div>
      </template>
    </DragTable>
    <edit v-model="show" :info="detail"></edit>
    <go-dialog v-model="visible" width="300px" height="100px">
      <div class="flex">
        <el-radio v-model="type" :label="5">5个模式</el-radio>
        <el-radio v-model="type" :label="10">10个模式</el-radio>
      </div>
      <footer class="footer">
        <el-button type="primary" @click="cancel">取消</el-button>
        <el-button type="primary" @click="save">确定</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";
import Edit from "./Edit.vue";

export default defineComponent({
  components: { Edit },
  setup(props, { root }) {
    const show = ref(false)
    const detail = reactive({data: {}});
    const visible = ref(false)
    const oldType = ref(5)
    const type = ref(5)
    const redirect_type = {
      1: 'APP内链接跳转',
      2: '搜索页',
      3: '帮助中心',
      4: '发现页-商品分类页',
      5: '随心购列表页',
    }
    const header = [
      { name: "序号", value: "sort" },
      { name: "图标", value: "img", width: 160, slot: "image" },
      { name: "名称", value: "name" },
      { name: "跳转", slot: "jump" },
      { name: "更新时间", value: "update_time" },
      { name: "操作人", value: "operation_user" },
      { name: "操作", value: "", slot: "control" },
    ];
    const list = reactive({data: []});
    const getList = ()=>{
      try {
        root.$axios.get('/cms/jingangwei/list')
        .then(res=>{
          list.data = res.data
        })
      } catch (error) {
        console.error(error)
      }
    }
    watch(show,val=>{
      if(!val) getList()
    })
    const getShowNum = () => {
      root.$axios.get(`/cms/jingangwei/getShowNum`)
      .then(res=>{
        visible.value = true
        type.value = res.data
        oldType.value = res.data
      })
      
    }
    const bannerEdit = async (data) => {
      try {
        const res = await root.$axios.get('/cms/jingangwei/detail/'+data.id)
        if(res.code === 10000) {
          res.data.url = res.data.img
          detail.data = res.data
          show.value = true;
        }
      } catch (error) {
        console.error(error)
      }
    };
    const cancel = () => {
      visible.value = false
      type.value = oldType.value
    }
    const save = () => {
      root.$axios.post(`/cms/jingangwei/setShowNum`,{
        show_num: type.value
      })
      .then(()=>{
        visible.value = false
        oldType.value = type.value
        getList()
      })
      
    }
    const handle = data => {
      const arr = []
        data.forEach(item => {
        arr.push({ id: item.id, sort: item.sort })
      });
      root.$axios.post(`/cms/jingangwei/sort`,{
        sort_list: arr
      })
      .catch(err=>{
        getList()
      })
    }
    getList()
    return {
      list,
      header,
      show,
      visible,
      detail,
      type,
      redirect_type,
      bannerEdit,
      save,
      cancel,
      handle,
      getShowNum
    };
  },
});
</script>
<style lang="scss" scoped>
@mixin circle() {
  position: absolute;
  content: " ";
  display: block;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: $safeColor;
}
.table-header{
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
  flex-direction: row-reverse
}
.title-style{
  @include textover();
}
.table-img{
  width: 100%;
  object-fit: contain;
}
.status {
  color: $safeColor;
  position: relative;
  color: $safeColor;
  padding-left: 13px;
  &::after {
    @include circle();
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  &.active {
    color: $wordColor;
    &::after {
      background-color: $wordColor;
    }
  }
}
.control {
  display: flex;
  span {
    margin-right: 15px;
    cursor: pointer;
    display: block;
    &:nth-of-type(1) {
      color: $mainColor;
    }
    &:nth-of-type(2) {
      color: $dangerColor;
    }
    &:nth-of-type(3) {
      color: $wordColor;
      margin-right: 0;
    }
  }
}
.flex{
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
}
.footer{
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}
</style>
